import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  message,
  Modal,
  Table,
  TableColumnsType,
} from 'antd';
import { BookOutlined, HomeOutlined } from '@ant-design/icons';
import { useAuth } from '../contexts/auth';
import api from '../services/api';
import { ItemType } from '../components/Library/ItemType';
import LibraryCopyModalContent from '../components/Library/LibraryCopyModalContent';

export default function LibraryUsers() {
  const { isAdmin } = useAuth();

  const [items, setItems] = useState<ItemType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ItemType | null>(null);

  async function getItems() {
    if (!isAdmin()) {
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await api.get('library/users');

      setItems(
        data.data.map((item: any) => ({
          ...item,
          key: item.id,
        }))
      );
    } catch (e: any) {
      message.error(e.message, 3);
      if (
        e.isAxiosError &&
        e.response.status === 422 &&
        !!e.response.data.errors
      ) {
        message.error(
          <div style={{ textAlign: 'left' }}>
            <pre>{JSON.stringify(e.response.data.errors, null, 2)}</pre>
          </div>,
          5
        );
      }
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getItems();
  }, []);

  function showCopyModal(item: ItemType) {
    setSelectedItem(item);
    setIsModalOpen(true);
  }

  function hideCopyModal() {
    setSelectedItem(null);
    setIsModalOpen(false);
  }

  const columns: TableColumnsType<ItemType> = [
    {
      title: '#',
      dataIndex: 'id',
      sorter: (a: ItemType, b: ItemType) => (a.id > b.id ? 1 : -1),
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: (a: ItemType, b: ItemType) => a.name.localeCompare(b.name),
      key: 'name',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      sorter: (a: ItemType, b: ItemType) => a.email.localeCompare(b.email),
      key: 'email',
    },
    {
      title: 'Registros',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      sorter: (a: ItemType, b: ItemType) => (a.total > b.total ? 1 : -1),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, item) => (
        <Button
          onClick={() => showCopyModal(item)}
          disabled={item.total < 1}
          ghost={item.total < 1}
        >
          Copiar
        </Button>
      ),
    },
  ];

  return isAdmin() ? (
    <div style={{ marginBottom: '5rem' }}>
      <Breadcrumb
        items={[
          {
            href: '',
            title: <HomeOutlined />,
          },
          {
            href: '',
            title: (
              <>
                <BookOutlined />
                <span>Biblioteca</span>
              </>
            ),
          },
          {
            title: 'Usuários',
          },
        ]}
        style={{ marginBottom: '1.5rem' }}
      />

      <Table
        loading={isLoading}
        size='small'
        dataSource={items}
        columns={columns}
        pagination={false}
      />
      <Modal
        open={isModalOpen}
        onOk={() => hideCopyModal()}
        onCancel={() => hideCopyModal()}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText='Fechar'
        width={800}
      >
        {selectedItem && (
          <LibraryCopyModalContent
            selectedItem={selectedItem}
            items={items}
            // eslint-disable-next-line react/jsx-no-bind
            getItems={getItems}
            // eslint-disable-next-line react/jsx-no-bind
            hideCopyModal={hideCopyModal}
          />
        )}
      </Modal>
    </div>
  ) : (
    <h1>Library Users</h1>
  );
}
