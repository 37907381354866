import { message } from 'antd';
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export function handleApiError(e: any) {
  message.error(e.message, 3);
  if (e.isAxiosError && e.response.status === 422 && !!e.response.data.errors) {
    message.error(
      <div style={{ textAlign: 'left' }}>
        <pre>{JSON.stringify(e.response.data.errors, null, 2)}</pre>
      </div>,
      5
    );
  }
}
