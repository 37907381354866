import React, { useState } from 'react';
import { Button, Flex, message, Popconfirm } from 'antd';
import { FaCheck } from 'react-icons/fa6';
import api from '../../services/api';
import { handleApiError } from '../../pages/Users/functions';

type UserToggleActiveProps = {
  userId: number;
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
};

export default function UserToggleActive({
  userId,
  isActive,
  setIsActive,
}: UserToggleActiveProps) {
  const actionText = isActive ? 'Desativar' : 'Ativar';

  const [hasSucceeded, setHasSucceeded] = useState(false);

  const toggleActive = async () => {
    try {
      const { data } = await api.post(`/admin/users/${userId}/toggle-active`);
      setIsActive(data.isActive);
      message.success('Alteração realizada com sucesso');
      setTimeout(() => {
        setHasSucceeded(false);
      }, 2000);
      setHasSucceeded(true);
    } catch (e) {
      handleApiError(e);
    }
  };

  return (
    <Flex justify='space-between' align='center'>
      <div>
        <strong style={{ fontSize: 'large' }}>{actionText} usuário</strong>
        <br />
        {actionText} o acesso do usuário ao sistema
      </div>

      <Popconfirm
        title={`${actionText} usuário`}
        description={`Deseja realmente ${actionText.toLowerCase()} o usuário?`}
        onConfirm={toggleActive}
        okText='Sim, prosseguir'
        okButtonProps={{ danger: true }}
        cancelText='Não'
      >
        <Button
          color='danger'
          variant='solid'
          icon={hasSucceeded ? <FaCheck /> : null}
        >
          {actionText}
        </Button>
      </Popconfirm>
    </Flex>
  );
}
