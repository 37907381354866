export const getDurationInSeconds = (duration: number) => duration / 10000000;

export const getFormattedDuration = (duration: number) => {
  const seconds = getDurationInSeconds(duration);
  return [
    Math.trunc(seconds / 60 / 60),
    Math.trunc((seconds / 60) % 60),
    Math.trunc(seconds % 60),
  ]
    .join(':')
    .replace(/\b(\d)\b/g, '0$1');
};
