import React, { useEffect, useState } from 'react';
import {
  Button,
  message,
  Popconfirm,
  Table,
  TableColumnsType,
  Tag,
} from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import { handleApiError } from './functions';

type UserType = {
  id: number;
  key: number;
  name: string;
  loginToken: string;
  email: string;
  isActive: boolean;
  trialEndsAt: string;
  createdAt: string;
  lastLoginAt: string;
  localizedTrialEndsAt: string;
  localizedCreatedAt: string;
  localizedLastLoginAt: string;
};

export default function UsersIndexPage() {
  const { isAdmin } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([] as UserType[]);

  async function getUsers() {
    if (!isAdmin()) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await api.get('/admin/users');
      const { data } = response.data;

      setUsers(
        data.map((item: any) => ({
          ...item,
          key: item.id,
          loginToken: item.loginToken || '',
          trialEndsAt: item.trialEndsAt || '',
          createdAt: item.createdAt || '',
          lastLoginAt: item.lastLoginAt || '',
          localizedTrialEndsAt: item.trialEndsAt
            ? new Date(item.trialEndsAt)
                .toLocaleString('pt-BR')
                .substring(0, 10)
            : '',
          localizedCreatedAt: item.createdAt
            ? new Date(item.createdAt).toLocaleString('pt-BR').replace(',', '')
            : '',
          localizedLastLoginAt: item.lastLoginAt
            ? new Date(item.lastLoginAt)
                .toLocaleString('pt-BR')
                .replace(',', '')
            : '',
        }))
      );
    } catch (e: any) {
      handleApiError(e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const columns: TableColumnsType<UserType> = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: UserType, b: UserType) => (a.id > b.id ? 1 : -1),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: (a: UserType, b: UserType) => a.name.localeCompare(b.name),
      key: 'name',
      render: (text, record) => <Link to={`/users/${record.id}`}>{text}</Link>,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      sorter: (a: UserType, b: UserType) => a.email.localeCompare(b.email),
      key: 'email',
    },
    {
      title: 'Ativo?',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) =>
        isActive ? <Tag color='green'>Sim</Tag> : <Tag>Não</Tag>,
    },
    {
      title: 'Trial',
      dataIndex: 'localizedTrialEndsAt',
      key: 'localizedTrialEndsAt',
      sorter: (a: UserType, b: UserType) =>
        a.trialEndsAt.localeCompare(b.trialEndsAt),
      render: (value: string) =>
        value.length > 0 ? <span>Até {value}</span> : <span>Não</span>,
    },
    {
      title: 'Criado em',
      dataIndex: 'localizedCreatedAt',
      key: 'localizedCreatedAt',
      sorter: (a: UserType, b: UserType) =>
        a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: 'Último acesso',
      dataIndex: 'localizedLastLoginAt',
      key: 'localizedLastLoginAt',
      sorter: (a: UserType, b: UserType) =>
        a.lastLoginAt.localeCompare(b.lastLoginAt),
    },
  ];

  return isAdmin() ? (
    <div>
      <h1>Usuários</h1>
      <Table
        loading={isLoading}
        size='small'
        dataSource={users}
        columns={columns}
        pagination={false}
      />
    </div>
  ) : (
    <div />
  );
}
