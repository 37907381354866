import { Button, Card, Form, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { ItemType } from './ItemType';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';

type LibraryCopyModalContentProps = {
  selectedItem: ItemType;
  items: ItemType[];
  getItems: () => void;
  hideCopyModal: () => void;
};

export default function LibraryCopyModalContent({
  selectedItem,
  items,
  getItems,
  hideCopyModal,
}: LibraryCopyModalContentProps) {
  const { isAdmin } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  async function doCopy(target: ItemType) {
    if (!isAdmin()) {
      return;
    }

    setIsLoading(true);
    try {
      await api.post('library/clone', {
        origin: selectedItem.id,
        target,
      });
      await getItems();
      hideCopyModal();
    } catch (e: any) {
      message.error(e.message, 3);
      if (
        e.isAxiosError &&
        e.response.status === 422 &&
        !!e.response.data.errors
      ) {
        message.error(
          <div style={{ textAlign: 'left' }}>
            <pre>{JSON.stringify(e.response.data.errors, null, 2)}</pre>
          </div>,
          5
        );
      }
    }

    setIsLoading(false);
  }

  const onFinish = async (values: any) => {
    await doCopy(values.target);
  };

  useEffect(() => {
    form.resetFields();
  }, [selectedItem]);

  return (
    <div>
      <h2>Copiar Biblioteca</h2>
      <Card size='small' bordered>
        <div>
          <strong>Nome:</strong> {selectedItem.name}
          <br />
          <strong>E-mail:</strong> {selectedItem.email}
          <br />
          <strong>Biblioteca:</strong> {selectedItem.total} registro
          {selectedItem.total !== 1 && 's'}
        </div>
      </Card>

      {selectedItem.total >= 100 && (
        <Card
          size='small'
          bordered
          style={{ backgroundColor: '#ffd69e', margin: '1rem 0' }}
        >
          Este usuário possui uma Biblioteca grande. A operação de cópia pode
          levar alguns segundos adicionais. Ao continuar, por favor aguarde a
          conclusão sem sair da página.
        </Card>
      )}

      <Form form={form} onFinish={onFinish}>
        <div style={{ fontSize: 'larger', margin: '1rem 0' }}>
          Escolha para qual usuário deseja copiar a Biblioteca de{' '}
          <strong>{selectedItem.name}</strong>:
        </div>
        <Form.Item
          name='target'
          rules={[
            {
              required: true,
              message: `Informe o usuário que receberá uma cópia da Biblioteca de ${selectedItem.name}`,
            },
          ]}
        >
          <Select
            showSearch
            placeholder='Quem irá receber a Biblioteca?'
            optionFilterProp='label'
            size='large'
            options={items
              .filter((item: ItemType) => item.id !== selectedItem.id)
              .map((item: ItemType) => ({
                value: item.id,
                label: `${item.name} <${item.email}>`,
              }))}
          />
        </Form.Item>

        <Card size='small' bordered style={{ backgroundColor: '#FFB0B0' }}>
          <p>
            <strong>Importante!</strong> No momento a cópia da Biblioteca de um
            usuário substituirá os registros do destinatário. Proceda com
            cautela. Em breve será possível manter as duas Bibliotecas.
          </p>
          <Form.Item style={{ margin: 0 }}>
            <Button type='primary' htmlType='submit' loading={isLoading} danger>
              Confirmar cópia
            </Button>
          </Form.Item>
        </Card>
      </Form>
    </div>
  );
}
