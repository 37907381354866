import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  FloatButton,
  Form,
  Input,
  message,
  Modal,
  Row,
  Statistic,
  StatisticProps,
  Table,
  TableColumnsType,
  TimeRangePickerProps,
} from 'antd';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

import { useSearchParams } from 'react-router-dom';
import CountUp from 'react-countup';
import { DownloadOutlined } from '@ant-design/icons';
import api from '../../services/api';
import AiCreditDetails from '../../components/AiCreditDetails/AiCreditDetails';
import { useAuth } from '../../contexts/auth';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear); // import locale
const { RangePicker } = DatePicker;

type ItemType = {
  key: string;
  uuid: string;
  rawDate: string;
  createdAt: string;
  userName: string;
  userEmail: string;
  metadata_total_tokens: number;
  metadata_total_price: number;
  metadata_whisper_cost: number;
  hasMetadata: boolean;
};

type FilterType = {
  startDate?: string;
  endDate?: string;
  email?: string;
};

type SummaryType = {
  amount: number;
  total_tokens: number;
  total_price: number;
  whisper_cost: number;
};

export default function AiCreditAssistant() {
  const { isAdmin } = useAuth();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [items, setItems] = useState([] as ItemType[]);
  const [summary, setSummary] = useState({
    amount: 0,
    total_price: 0,
    total_tokens: 0,
  } as SummaryType);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);

  function setFilter(filter: FilterType) {
    const format = 'YYYY-MM-DD';
    const startDate = dayjs(filter.startDate, format);
    const endDate = dayjs(filter.endDate, format);

    form.setFieldsValue({
      dateRange: [startDate, endDate],
      email: filter.email,
    });
    setSearchParams({
      startDate: startDate.format(format) ?? '',
      endDate: endDate.format(format) ?? '',
      email: filter.email ?? '',
    });
  }

  async function getItems(submittedFilter: FilterType) {
    if (!isAdmin()) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await api.get('ai-credits/assistant', {
        params: {
          ...submittedFilter,
        },
      });
      const { filter, summary: s, data } = response.data;

      setItems(
        data.map((item: any) => ({
          ...item,
          createdAt: new Date(item.createdAt)
            .toLocaleString('pt-BR')
            .replace(',', ''),
          rawDate: item.createdAt,
          key: item.uuid,
        }))
      );
      setFilter(filter);
      setSummary(s);
    } catch (e: any) {
      message.error(e.message, 3);
      if (
        e.isAxiosError &&
        e.response.status === 422 &&
        !!e.response.data.errors
      ) {
        message.error(
          <div style={{ textAlign: 'left' }}>
            <pre>{JSON.stringify(e.response.data.errors, null, 2)}</pre>
          </div>,
          5
        );
      }
    }

    setIsLoading(false);
  }

  const showDetailsModal = (uuid: string) => {
    setSelectedUuid(uuid);
    setIsModalOpen(true);
  };

  useEffect(() => {
    getItems({
      startDate: searchParams.get('startDate') ?? undefined,
      endDate: searchParams.get('endDate') ?? undefined,
      email: searchParams.get('email') ?? undefined,
    });
  }, []);

  const columns: TableColumnsType<ItemType> = [
    {
      title: '#',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (value) => (
        <span style={{ fontFamily: 'monospace', fontSize: 'small' }}>
          {value}
        </span>
      ),
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a: ItemType, b: ItemType) => a.rawDate.localeCompare(b.rawDate),
    },
    {
      title: 'Nome',
      dataIndex: 'userName',
      sorter: (a: ItemType, b: ItemType) =>
        a.userName.localeCompare(b.userName),
      key: 'userName',
    },
    {
      title: 'E-mail',
      dataIndex: 'userEmail',
      sorter: (a: ItemType, b: ItemType) =>
        a.userEmail.localeCompare(b.userEmail),
      key: 'userEmail',
    },
    {
      title: 'total_tokens',
      dataIndex: 'metadata_total_tokens',
      key: 'metadata_total_tokens',
      align: 'right',
    },
    {
      title: 'total_price',
      dataIndex: 'metadata_total_price',
      key: 'metadata_total_price',
      align: 'right',
    },
    {
      title: 'whisper_cost',
      dataIndex: 'metadata_whisper_cost',
      key: 'metadata_whisper_cost',
      align: 'right',
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, item) => (
        <Button
          onClick={() => showDetailsModal(item.uuid)}
          ghost={!item.hasMetadata}
        >
          Detalhes
        </Button>
      ),
    },
  ];

  const now = dayjs();
  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'Hoje', value: [now, now] },
    { label: 'Ontem', value: [now.add(-1, 'd'), now.add(-1, 'd')] },
    { label: 'Últimos 7 dias', value: [now.add(-7, 'd'), now] },
    { label: 'Últimos 14 dias', value: [now.add(-14, 'd'), now] },
    { label: 'Últimos 30 dias', value: [now.add(-30, 'd'), now] },
    { label: 'Últimos 60 dias', value: [now.add(-60, 'd'), now] },
  ];

  const onFinish = (values: any) => {
    getItems({
      email: values.email ?? undefined,
      startDate: values.dateRange[0].format('YYYY-MM-DD'),
      endDate: values.dateRange[1].format('YYYY-MM-DD'),
    });
  };

  const formatter: StatisticProps['formatter'] = (value) => (
    <CountUp end={value as number} duration={1} separator='.' />
  );

  const usdFormatter: StatisticProps['formatter'] = (value) => (
    <CountUp
      end={value as number}
      duration={1}
      separator='.'
      decimals={6}
      decimal=','
      prefix='USD '
    />
  );

  const exportToCsv = () => {
    try {
      const keys = {
        uuid: '#',
        createdAt: 'Data',
        userName: 'Nome',
        userEmail: 'E-mail',
        metadata_total_tokens: 'total_tokens',
        metadata_total_price: 'total_price',
        metadata_whisper_cost: 'whisper_cost',
      };
      const csvData = [
        Object.values(keys).join(','),
        ...items.map((item: any) =>
          Object.keys(keys)
            .map((key: string) => item[key])
            .join(',')
        ),
      ].join('\n');

      const blob = new Blob([csvData], { type: 'text/csv' });
      const href = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute(
        'download',
        `ai-credits-usage-assistant-${dayjs().unix()}.csv`
      );

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      message.success('Exportação realizada com sucesso');
    } catch (error) {
      message.error('Houve um erro ao exportar o arquivo', 3);
      console.error('Failed to export csv file:', error);
      throw error;
    }
  };

  return isAdmin() ? (
    <div style={{ marginBottom: '5rem' }}>
      <h1>Relatório de IA - Assistant</h1>
      <Form form={form} onFinish={onFinish} layout='inline'>
        <Form.Item
          name='dateRange'
          label='Período'
          rules={[{ required: true, message: 'Informe o período desejado' }]}
        >
          <RangePicker
            format='DD/MM/YYYY'
            presets={rangePresets}
            placeholder={['Data inicial', 'Data final']}
          />
        </Form.Item>
        <Form.Item name='email' label='E-mail'>
          <Input placeholder='E-mail' />
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Filtrar
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <div>
        <Row gutter={16}>
          <Col span={6}>
            <Card>
              <Statistic
                title='Quantidade'
                value={summary.amount}
                precision={2}
                formatter={formatter}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title='total_tokens'
                value={summary.total_tokens}
                precision={2}
                formatter={formatter}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title='total_price'
                value={summary.total_price}
                precision={9}
                formatter={usdFormatter}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title='whisper_cost'
                value={summary.whisper_cost}
                precision={9}
                formatter={usdFormatter}
              />
            </Card>
          </Col>
        </Row>
      </div>

      <Divider />

      <Table
        loading={isLoading}
        size='small'
        dataSource={items}
        columns={columns}
        pagination={{
          defaultPageSize: 100,
        }}
      />

      {items.length > 0 && (
        <FloatButton
          type='primary'
          shape='square'
          onClick={exportToCsv}
          tooltip='Exportar'
          icon={<DownloadOutlined />}
        />
      )}

      <Modal
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText='Fechar'
      >
        <AiCreditDetails uuid={selectedUuid} />
      </Modal>
    </div>
  ) : (
    <div />
  );
}
