import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Card,
  Descriptions,
  DescriptionsProps,
  Divider,
  Splitter,
  Tag,
  Typography,
} from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { FaMoneyBillTransfer } from 'react-icons/fa6';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import { handleApiError } from './functions';
import UsersResetPassword from '../../components/User/UserResetPassword';
import UserToggleActive from '../../components/User/UserToggleActive';
import UserTrial from '../../components/User/UserTrial';
import UserLoginToken from '../../components/User/UserLoginToken';

const { Title } = Typography;

type UserType = {
  key: number;
  id: number;
  name: string;
  loginToken: string | null;
  email: string;
  phone: string;
  cpf: string | null;
  crm: string | null;
  gender: string | null;
  picture: string | null;
  isActive: boolean;
  trialEndsAt: string;
  createdAt: string;
  lastLoginAt: string;
};

export default function UsersShowPage() {
  const { isAdmin } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState<UserType | null>(null);
  const { userId } = useParams();

  async function getUser() {
    if (!isAdmin()) {
      return;
    }
    setIsLoading(true);

    try {
      const { data } = await api.get(`/admin/users/${userId}`);
      setUser({
        ...data,
      });
    } catch (e) {
      handleApiError(e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getUser();
  }, [userId]);

  const setLoginToken = (loginToken: string | null) => {
    if (user) {
      setUser({
        ...user,
        loginToken,
      });
    }
  };

  const setIsActive = (isActive: boolean) => {
    if (user) {
      setUser({
        ...user,
        isActive,
      });
    }
  };

  const setTrialEndsAt = (trialEndsAt: string) => {
    if (user) {
      setUser({
        ...user,
        trialEndsAt,
      });
    }
  };

  const items: DescriptionsProps['items'] = [
    {
      key: 'isActive',
      children: (
        <Tag color={user?.isActive ? 'success' : 'error'}>
          {user?.isActive ? 'Ativo' : 'Inativo'}
        </Tag>
      ),
    },
    {
      key: 'email',
      label: 'E-mail',
      children: user?.email,
    },
    {
      key: 'phone',
      label: 'Telefone',
      children: user?.phone,
    },
    {
      key: 'cpf',
      label: 'CPF',
      children: user?.cpf,
    },
    {
      key: 'crm',
      label: 'CRM',
      children: user?.crm,
    },
    {
      key: 'createdAt',
      label: 'Criado em',
      children:
        user?.createdAt && new Date(user.createdAt).toLocaleString('pt-BR'),
    },
    {
      key: 'trialEndsAt',
      label: 'Trial até',
      children:
        user?.trialEndsAt &&
        new Date(user.trialEndsAt).toLocaleDateString('pt-BR'),
    },
    {
      key: 'lastLoginAt',
      label: 'Último acesso',
      children:
        user?.lastLoginAt && new Date(user.lastLoginAt).toLocaleString('pt-BR'),
    },
  ];

  return isAdmin() && user ? (
    <>
      <Breadcrumb
        items={[
          {
            href: '/',
            title: <HomeOutlined />,
          },
          {
            href: '/users',
            title: (
              <>
                <UserOutlined />
                <span>Usuários</span>
              </>
            ),
          },
          {
            href: `/users/${userId}`,
            title: user?.name,
          },
        ]}
        style={{ marginBottom: '1.5rem' }}
      />

      <Title level={2}>{user.name}</Title>

      <Splitter>
        <Splitter.Panel defaultSize='20%'>
          <Title level={3}>Dados</Title>
          <Descriptions items={items} column={1} size='small' />
        </Splitter.Panel>
        <Splitter.Panel>
          <div style={{ maxWidth: '50rem', marginLeft: '2rem' }}>
            <Title level={3}>Assinatura</Title>
            <Card>
              <div style={{ fontSize: '2rem' }}>
                <FaMoneyBillTransfer />
              </div>
              Informações sobre pagamento e o plano contratado estarão
              disponíveis aqui em breve.
            </Card>

            <Title level={3}>Integrações</Title>
            <Card>
              <UserLoginToken
                userId={user.id}
                name={user.name}
                loginToken={user.loginToken}
                setLoginToken={setLoginToken}
              />
            </Card>

            <Title level={3}>Administração</Title>
            <Card style={{ borderColor: 'red' }}>
              <UserTrial
                userId={user.id}
                initialValue={user.trialEndsAt}
                setTrialEndsAt={setTrialEndsAt}
              />
              <Divider />
              <UsersResetPassword userId={user.id} />
              <Divider />
              <UserToggleActive
                userId={user.id}
                isActive={user.isActive}
                setIsActive={setIsActive}
              />
            </Card>
          </div>
        </Splitter.Panel>
      </Splitter>
    </>
  ) : (
    <div />
  );
}
