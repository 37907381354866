import React, { useState } from 'react';
import { Button, Flex, Input, message, Popconfirm } from 'antd';
import { FaCheck, FaCopy, FaKey, FaTrash } from 'react-icons/fa6';
import api from '../../services/api';
import { handleApiError } from '../../pages/Users/functions';

type UserLoginTokenProps = {
  userId: number;
  name: string;
  loginToken: string | null;
  setLoginToken: (loginToken: string | null) => void;
};

export default function UserLoginToken({
  userId,
  name,
  loginToken,
  setLoginToken,
}: UserLoginTokenProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  const generateLoginToken = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post(
        `/admin/users/${userId}/generate-login-token`
      );

      setLoginToken(data.loginToken);
      message.success(`Token de ${name} gerado com sucesso!`);
    } catch (e) {
      handleApiError(e);
    }
    setIsLoading(false);
  };

  const removeLoginToken = async () => {
    setIsLoading(true);
    try {
      await api.delete(`/admin/users/${userId}/remove-login-token`);

      setLoginToken(null);
      message.info(`Token de ${name} removido com sucesso!`);
    } catch (e) {
      handleApiError(e);
    }
    setIsLoading(false);
  };

  const copyToken = async () => {
    if (!loginToken) {
      return;
    }

    await navigator.clipboard.writeText(loginToken);

    message.success('Token copiado com sucesso');
    setTimeout(() => {
      setCopied(false);
    }, 4000);
    setCopied(true);
  };

  return (
    <div>
      <div>
        <strong>Login por token</strong>
      </div>
      {loginToken ? (
        <>
          <p>
            <Input
              size='large'
              placeholder='large size'
              prefix={<FaKey />}
              readOnly
              value={loginToken}
            />
          </p>
          <Flex gap='0.5rem' justify='flex-end'>
            <Popconfirm
              title={`Remover o token de login de ${name}?`}
              description={
                <div>
                  <p>
                    Deseja realmente remover o token de login deste usuário?
                    <br />
                    {name} ainda poderá efetuar o login com e-mail senha
                    pessoal, caso possua.
                  </p>
                </div>
              }
              onConfirm={removeLoginToken}
              okText='Sim, remover'
              okButtonProps={{ danger: true }}
              cancelText='Não'
            >
              <Button
                color='danger'
                variant='filled'
                loading={isLoading}
                icon={<FaTrash />}
              >
                Remover
              </Button>
            </Popconfirm>
            <Button
              icon={copied ? <FaCheck /> : <FaCopy />}
              onClick={copyToken}
              type='primary'
            >
              Copiar
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <p>
            O usuário não possui um <i>login token</i>. Clique no botão abaixo
            para gerar um novo token.
          </p>
          <Button
            onClick={() => generateLoginToken()}
            loading={isLoading}
            color='primary'
            variant='solid'
          >
            Gerar
          </Button>
        </>
      )}
    </div>
  );
}
